export interface SellerInterface {
  Aktyvus?: number;
  Blokuotas?: number;
  DraudimasGalimas?: number;
  Emailas: string;
  ImonesID?: number;
  ImonesPavadinimas?: string;
  KopijosPasiulymo?: number;
  KopijosSalygu?: number;
  KopijosSutarties?: number;
  KopijosSutikimo?: number;
  Loginas: string;
  MaksimaliFinansuojamaSuma?: number;
  MatoIeskoti?: number;
  MatoIsipareigojimus?: number;
  NematoKliento?: number;
  PardavejoID?: number;
  ParduotuvesID?: number;
  ParduotuvesPavadinimas?: string;
  ParduotuvesVartotojas?: number;
  Pavarde?: string;
  RolesID?: number;
  Sesija?: unknown;
  Telefonas: string;
  MobilusTelefonas?: string;
  Vardas?: string;
  VardasPavarde?: string;
  Autentifikacija?: SellerAuthentication;
  DviejuFaktoriuPrisijungimas?: string;
  EmailasPatvirtintas?: number;
  GarantijosDraudimas?: string;
  TelefonasPatvirtintas?: number;
  IgaliojimoTerminas?: string;
  PaskutinisPrisijungimas?: string;
  NeprivalomiDokumentai?: number;
  VartotojoKodas?: string;
}

export enum SellerAuthentication {
  EMAIL = "1",
  SMS = "2",
}

export enum SellerBlockedCodes {
  NOT_BLOCKED = 0,
  TOO_MANY_ATTEMPTS = 1004,
  TOO_LONG_OFLINE = 1005,
}
