<template>
  <div :class="computedWrapperClass">
    <b-button-group :class="buttonGroupClass">
      <b-dropdown
        :id="id"
        class="w-100"
        :size="size"
        :disabled="disabled"
        drop
        :text="internalValue || placeholderText"
        variant="light"
        :menu-class="computedDropDownClass"
        :toggle-class="computedToggleClass"
        @shown="setSearchInputFocus"
        @hidden="$emit('drop-hidden', true)"
        :boundary="dropDownBoundaryResolved"
        :right="dropRight"
        :left="dropLeft"
      >
        <template #button-content>
          <span
            :title="internalValue"
            class="text-truncate font-arial d-flex align-items-center justify-content-between w-100 position-relative"
          >
            <span class="mr-3">{{ truncate ? $options.filters.truncate(internalValue, truncate) : internalValue }}</span>
            <b-spinner v-if="listLoading" small variant="primary" class="position-absolute right-0"></b-spinner>
          </span>
        </template>

        <b-dropdown-text v-if="!noFilterInput" text-class="px-3 pt-2">
          <b-input
            class="text-truncate"
            ref="filterInput"
            v-model="filter"
            :formatter="formatter"
            @input="$emit('filter', filter)"
            autofocus
            :placeholder="searchPlaceholder"
            :id="`input-${_uid}`"
          />
        </b-dropdown-text>
        <b-dropdown-group class="max-h-limited overflow-y-auto" :id="`dropGroup-${_uid}`">
          <b-dropdown-item-button v-if="firstOption.length" @click="pickOption(firstOption[0])" :button-class="{ 'text-wrap': multiline }">
            <span
              class="text-gray"
              v-if="invalidOptionFormat && invalidValues.length && invalidValues.includes(firstOption[0][valueField])"
              v-html="`${invalidOptionFormat.replace(':item', firstOption[0][textField])}`"
            ></span>
            <template v-else>{{ firstOption[0][textField] }} </template>
          </b-dropdown-item-button>
          <b-dropdown-item-button
            v-for="(fieldItem, index) in filteredOptions"
            :key="`${fieldItem[valueField]}-${index}`"
            @click="pickOption(fieldItem)"
            :active="isActive(fieldItem)"
            :button-class="{
              'text-wrap': multiline,
              'rounded-top': index === 0 && !firstOption.length && noFilterInput,
              'rounded-bottom': index === lastOption && noFilterInput,
            }"
          >
            <span
              class="text-gray"
              v-if="invalidOptionFormat && invalidValues.length && invalidValues.includes(fieldItem[valueField])"
              v-html="`${invalidOptionFormat.replace(':item', fieldItem[textField])}`"
            ></span>
            <span v-else>
              {{ fieldItem[textField] }}
            </span>
          </b-dropdown-item-button>
          <b-dropdown-item-button v-if="canAddOption" @click="pickAsNewOption({ [valueField]: filter, [textField]: filter })">
            {{ $t("searchableSelect.addOption") }}
          </b-dropdown-item-button>
        </b-dropdown-group>
      </b-dropdown>
    </b-button-group>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "SearchableSelect",
  props: {
    id: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "md",
      validator: function (value: string): boolean {
        return ["sm", "md", "lg"].includes(value);
      },
    },
    valueField: {
      type: String,
      default: "id",
    },
    textField: {
      type: String,
      default: "name",
    },
    value: [String, Number, Object],
    returnObject: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    buttonGroupClass: {
      type: String,
      default: "w-100",
    },
    wrapperClass: {
      type: String,
      default: "w-100",
    },
    toggleClass: {
      type: String,
    },
    toggleDefaultBorderClass: {
      type: String,
      default: "border-gray-1",
    },
    noBlankOption: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: [String, Boolean, Number],
      default: null,
    },
    name: {
      type: String,
      default: "searchableSelect",
    },
    state: {
      type: Boolean,
      default: true,
    },
    noFilter: {
      // this will prevent option list from filtering
      type: Boolean,
      default: false,
    },
    noFilterInput: {
      // this will hide filter input field
      type: Boolean,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      default: false,
    },
    dropDownBoundary: {
      type: String,
      default: "scrollParent",
    },
    dropDownClass: {
      type: String,
      default: "shadow",
    },
    truncate: {
      type: [String, Number],
      default: null,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    initPassedValue: {
      type: Boolean,
      default: false,
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
    invalidOptionFormat: {
      type: String,
      default: "",
    },
    invalidValues: {
      type: Array,
    },
    formatter: {
      type: Function,
      default: null,
    },
    dropLeft: {
      type: Boolean,
      default: false,
    },
    dropRight: {
      type: Boolean,
      default: false,
    },
    fallbackToPlaceholder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      internalValue: null as any,
      filter: "",
      placeholderText: "",
      dropDownBoundaryResolved: "scrollParent" as string | Element | null,
    };
  },
  mounted() {
    this.placeholderText = this.placeholder?.length ? this.placeholder : this.defaultPlaceholder;
    this.initInput(this.value);

    // to get proper positioning here we need to get HTML reference if required
    this.dropDownBoundaryResolved = this.resolveDropDownBoundary();
  },
  methods: {
    setSearchInputFocus(event: any) {
      if (!this.noFilterInput) {
        const el = this.$refs.filterInput as HTMLElement;
        el.focus();
      }

      this.$emit("drop-shown", event);

      if (this.filter && this.value && !this.filter.length && this.value.length && this.value !== this.placeholderText) {
        this.filter = this.value;
      }
    },
    initInput(val: any) {
      if (this.initPassedValue && ((val && val.length) || val === 0 || val === null)) {
        this.internalValue = val;

        if (this.defaultValue !== null && +val === +this.defaultValue) {
          this.internalValue = this.firstOption[0][this.textField];
        }

        if (this.value !== this.placeholderText) {
          this.filter = val;
        }
      }

      if (val === undefined || val === null || val === "") {
        this.internalValue = this.defaultPlaceholder;
        this.filter = "";

        return;
      }

      if (!Array.isArray(this.options) || !this.options.length) {
        return;
      }

      if (!this.options.length && this.noFilter && (val.length || Object.keys(val).length)) {
        this.internalValue = val;
        this.filter = val.length ? val : val[this.valueField];

        return;
      }

      const match: any = this.options.find(
        (item: any) => this.lowercaseValue(item[this.textField]) === this.lowercaseValue(val) || item[this.valueField] == val
      );

      if (match !== undefined) {
        this.internalValue = match[this.textField];
        return;
      }

      this.internalValue = this.fallbackToPlaceholder ? this.defaultPlaceholder : val;
    },
    lowercaseValue(val: any) {
      if (typeof val === "object") {
        return val[this.valueField].toString().toLowerCase();
      }

      return (val || "").toString().toLowerCase();
    },
    pickAsNewOption(option: any) {
      this.pickOption(option);
      this.$emit("add-new", option);
    },
    pickOption(option: any) {
      if (option === this.defaultValue) {
        // blank option picked
        this.$emit("input", this.defaultValue);
        this.internalValue = this.defaultPlaceholder;

        if (this.noFilter) {
          return;
        }

        this.filter = "";

        return;
      }

      if (this.returnObject) {
        this.$emit("input", JSON.parse(JSON.stringify(option)));
      } else {
        this.$emit("input", option[this.valueField]);
      }

      this.internalValue = option[this.textField];

      if (this.noFilter && this.internalValue !== this.defaultPlaceholder) {
        this.filter = this.internalValue;

        return;
      }

      this.filter = "";
    },
    isActive(option: any): boolean {
      // @todo: try to indicate that one active item is already found so no multiple selected items show in the list
      // if (!this.value || this.value === this.defaultValue) {
      if (this.value === this.defaultValue) {
        return false;
      }

      return (
        option[this.textField] === this.value ||
        option[this.valueField] === this.value[this.valueField] ||
        option[this.valueField] === this.value ||
        (typeof this.value === "number" && !isNaN(+option[this.valueField]) && +option[this.valueField] === this.value)
      );
    },
    removeLtSymbols(query: string) {
      const from = "ąàáäâęėèéëêįìíïîòóöôùúüûñçčšųūžĄÀÁÄÂĘĖÈÉËÊĮÌÍÏÎÒÓÖÔÙÚÜÛÑÇČŠŲŪŽ";
      const to = "aaaaaeeeeeeiiiiioooouuuunccsuuzAAAAAEEEEEEIIIIIOOOOUUUUNCCSUUZ";

      let out = query;
      for (let i = 0, l = from.length; i < l; i++) {
        out = out.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      return out;
    },
    resolveDropDownBoundary(): string | Element | null {
      // check if picked option is withing defaults
      if (["viewport", "window", "scrollParent"].includes(this.dropDownBoundary)) {
        return this.dropDownBoundary;
      }

      // try to locate HTML element
      return document.querySelector(this.dropDownBoundary);
    },
  },
  computed: {
    firstOption(): Array<{ [key: string]: any }> {
      let first: any[] = [];
      if (!this.noBlankOption) {
        first = [
          {
            [this.valueField]: this.defaultValue,
            [this.textField]: this.placeholderText,
          },
        ];
      }

      return first;
    },
    filteredOptions(): any[] {
      if (this.noFilter) {
        return this.options;
      }

      return this.options.filter((item: any) => {
        return this.textField in item ? item[this.textField].toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 : false;
      });
    },
    defaultPlaceholder(): string {
      return this.$options?.filters?.capitalize(this.$t("newApplication.dropDownChoose"));
    },
    searchPlaceholder(): string {
      return this.$options?.filters?.capitalize(this.$t("newApplication.search"));
    },
    canAddOption(): boolean {
      if (!this.allowAdd || !this.filter.length) {
        return false;
      }

      return !this.filteredOptions.some(
        (item) =>
          item[this.textField] === this.filter ||
          // check for fuzzy match - ignore LT specific symbols and dots
          this.removeLtSymbols(item[this.textField]).toLocaleLowerCase().replaceAll(".", "") ===
            this.removeLtSymbols(this.filter).toLocaleLowerCase().replaceAll(".", "")
      );
    },
    computedToggleClass(): string {
      const defaults = `${this.toggleClass ? this.toggleClass : "border-2 w-100 d-flex justify-content-between align-items-center"}`;
      return `${this.state ? this.toggleDefaultBorderClass : "border-danger"} ${defaults}`;
    },
    computedWrapperClass(): string {
      let parts = this.wrapperClass;
      if (!this.state) {
        parts += " is-invalid";
      }

      return parts;
    },
    computedDropDownClass(): string {
      let parts = this.dropDownClass;

      if (this.noFilterInput) {
        parts += " py-0 my-0";
      }

      return parts;
    },
    lastOption(): number {
      return this.filteredOptions.length - 1;
    },
  },
  watch: {
    value(val) {
      this.initInput(val);
    },
  },
});
</script>

<style lang="scss" scoped>
.max-h-limited {
  max-height: 250px;
}
.mw-50 {
  min-width: 250px;
}
.right-0 {
  right: 0;
}

button.dropdown-item:active > span,
button.dropdown-item.active > span {
  color: white !important;
}

.text-gray {
  color: #6c757d;
}
</style>
