import { DocumentUploadOptionInterface } from "@/interfaces/application";
import { CreditTypes } from "@/interfaces/applicationSettings";
import Vue from "vue";
import { mapGetters } from "vuex";

interface FileSignatureInterface {
  signature?: string;
  type: string;
  extension: string;
}

export enum fileTypesEnum {
  PERSONAL_DOCUMENT_COPY = 1,
  ID_FRONT = 3,
  ID_BACK = 4,
  PASSPORT = 7,
  INCOME_DECLARATION = 13,
  SB_INSURANCE_DOC = 14,
  REFINANCE_DOC = 15,
  OTHER = 16,
  RESIDENCE_PERMIT_FRONT = 18,
  RESIDENCE_PERMIT_BACK = 19,
  RESIDENCE_PERMIT_TEMPORARY_FRONT = 22,
  RESIDENCE_PERMIT_TEMPORARY_BACK = 23,
  ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO = 26,
  BANK_ACCOUNT_REPORT = 28,
  APPLICATION_COPY = 30,
  APPLICATION_MARKETING_AGREEMENT = 32,

  ID_FRONT_SPOUSE = 5,
  ID_BACK_SPOUSE = 6,
  PASSPORT_SPOUSE = 8,
  INCOME_DECLARATION_SPOUSE = 17,
  SB_INSURANCE_DOC_SPOUSE = 18,
  REFINANCE_DOC_SPOUSE = 19,
  RESIDENCE_PERMIT_FRONT_SPOUSE = 20,
  RESIDENCE_PERMIT_BACK_SPOUSE = 21,
  RESIDENCE_PERMIT_TEMPORARY_FRONT_SPOUSE = 24,
  RESIDENCE_PERMIT_TEMPORARY_BACK_SPOUSE = 25,
  ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO_SPOUSE = 27,
  BANK_ACCOUNT_REPORT_SPOUSE = 29,
  APPLICATION_COPY_SPOUSE = 31,
  APPLICATION_MARKETING_AGREEMENT_COPY_SPOUSE = 33,
  SIGNED_SBD_AGREEMENT = 34,
}

// read more about this approach of using mixins
// https://github.com/ktsn/vue-typed-mixins

const fileDownload = Vue.extend({
  methods: {
    downloadPDF(pdf: any, fileName = "test.pdf") {
      const linkSource = `data:application/octet-stream;charset=utf-8;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      downloadLink.innerHTML = fileName;
      downloadLink.href = linkSource;
      downloadLink.target = "_blank";
      downloadLink.download = fileName;
      const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      downloadLink.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(downloadLink.href);
    },
    downloadUploadedFile(content: string, fileName = "test", extension?: string) {
      let detectedMimeType = "image/jpeg";
      let fileExtension = ".jpg";
      const mime = this._detectMimeType(content, extension);

      if (mime) {
        detectedMimeType = mime.type;
        fileExtension = `.${mime.extension}`;
      }

      const linkSource = `data:${detectedMimeType};base64,${content}`;
      const downloadLink = document.createElement("a");

      downloadLink.href = linkSource;
      downloadLink.download = fileName + fileExtension;
      downloadLink.click();
    },
    _detectMimeType(content: string, extension?: string): null | FileSignatureInterface {
      let found: null | undefined | FileSignatureInterface = null;
      let signatures: FileSignatureInterface[] = this.commonMimeTypes;

      // try to detect file mime type out of content
      if (!extension?.length) {
        signatures = [
          {
            signature: "JVBERi0",
            type: "application/pdf",
            extension: "pdf",
          },
          {
            signature: "R0lGODdh",
            type: "image/gif",
            extension: "gif",
          },
          {
            signature: "R0lGODlh",
            type: "image/gif",
            extension: "gif",
          },
          {
            signature: "iVBORw0KGgo",
            type: "image/png",
            extension: "png",
          },
          {
            signature: "/9j/",
            type: "image/jpg",
            extension: "jpg",
          },
          {
            signature: "UEsDBBQABgAIAAAAIQAAhO",
            type: "application/vnd.ms-excel.sheet.macroEnabled.12",
            extension: "xlsm",
          },
          {
            signature: "UEsDBBQ",
            type: "application/vnd.ms-excel",
            extension: "xls",
          },
        ];

        found = signatures.find((sig) => sig.signature && content.indexOf(sig.signature) === 0);
      } else {
        found = signatures.find((sig) => sig.extension === extension);
      }

      if (found) {
        return found;
      }

      return null;
    },
  },
  computed: {
    ...mapGetters({
      applicationSettings: "newApplication/applicationSettings",
      isManager: "auth/isManager",
    }),
    fileTypeOptions: function (): DocumentUploadOptionInterface[] {
      let basicOptions = [
        // {
        //   value: 1,
        //   text: this.$t("newApplication.fileUpload.personalDocCopy")
        // },
        {
          value: fileTypesEnum.ID_FRONT,
          text: this.$t("newApplication.fileUpload.idFrontSide"),
        },
        {
          value: fileTypesEnum.ID_BACK,
          text: this.$t("newApplication.fileUpload.idBackSide"),
        },
        {
          value: fileTypesEnum.PASSPORT,
          text: this.$t("newApplication.fileUpload.passport"),
        },
        // disabled options at the moment
        // {
        //   value: "9",
        //   text: this.$t("newApplication.fileUpload.driverLicenseFront")
        // },
        // {
        //   value: "10",
        //   text: this.$t("newApplication.fileUpload.driverLicenseBack")
        // },
        {
          value: fileTypesEnum.INCOME_DECLARATION,
          text: this.$t("newApplication.fileUpload.incomeDeclaration"),
        },
        {
          value: fileTypesEnum.SB_INSURANCE_DOC,
          text: this.$t("newApplication.fileUpload.sbInsuranceDoc"),
        },
        {
          value: fileTypesEnum.REFINANCE_DOC,
          text: this.$t("newApplication.fileUpload.refinanceDoc"),
        },
        {
          value: fileTypesEnum.OTHER,
          text: this.$t("newApplication.fileUpload.otherDoc"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_FRONT,
          text: this.$t("newApplication.fileUpload.residencePermitFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_BACK,
          text: this.$t("newApplication.fileUpload.residencePermitBack"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_FRONT,
          text: this.$t("newApplication.fileUpload.residencePermitTemporaryFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_BACK,
          text: this.$t("newApplication.fileUpload.residencePermitTemporaryBack"),
        },
        {
          value: fileTypesEnum.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO,
          text: this.$t("newApplication.fileUpload.electronicResidencePermitCheckPhoto"),
        },
        {
          value: fileTypesEnum.BANK_ACCOUNT_REPORT,
          text: this.$t("newApplication.fileUpload.bankAccountReport"),
        },
        {
          value: fileTypesEnum.APPLICATION_COPY,
          text: this.$t("newApplication.fileUpload.applicationCopy"),
        },
        {
          value: fileTypesEnum.APPLICATION_MARKETING_AGREEMENT,
          text: this.$t("newApplication.fileUpload.applicationMarketingAgreementCopy"),
        },
      ];

      //  (rodoma tik vadybininko rolei)
      const managerOptions = [
        {
          value: fileTypesEnum.INCOME_DECLARATION_SPOUSE,
          text: this.$t("newApplication.fileUpload.decisionApprovalDocument"),
        },
      ];

      const spouseOptions = [
        {
          value: fileTypesEnum.ID_FRONT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseIdFrontSide"),
        },
        {
          value: fileTypesEnum.ID_BACK_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseIdBackSide"),
        },
        {
          value: fileTypesEnum.PASSPORT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spousePassport"),
        },
        // disabled options at the moment
        // {
        //   value: "11",
        //   text: this.$t("newApplication.fileUpload.spouseDriverLicenseFront")
        // },
        // {
        //   value: "12",
        //   text: this.$t("newApplication.fileUpload.spouseDriverLicenseBack")
        // }
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_FRONT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_BACK_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitBack"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_FRONT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitTemporaryFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_BACK_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitTemporaryBack"),
        },
        {
          value: fileTypesEnum.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseElectronicResidencePermitCheckPhoto"),
        },
        {
          value: fileTypesEnum.BANK_ACCOUNT_REPORT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseBankAccountReport"),
        },
        {
          value: fileTypesEnum.APPLICATION_COPY_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseApplicationCopy"),
        },
        {
          value: fileTypesEnum.APPLICATION_MARKETING_AGREEMENT_COPY_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseApplicationMarketingAgreementCopy"),
        },
      ];

      if (this.isManager) {
        basicOptions = [...basicOptions, ...managerOptions];
      }

      return this.applicationSettings?.creditType == CreditTypes.SPOUSE ? [...basicOptions, ...spouseOptions] : basicOptions;
    },
    allFileTypeOptions: function (): DocumentUploadOptionInterface[] {
      return [
        // {
        //   value: 1,
        //   text: this.$t("newApplication.fileUpload.personalDocCopy")
        // },
        {
          value: fileTypesEnum.ID_FRONT,
          text: this.$t("newApplication.fileUpload.idFrontSide"),
        },
        {
          value: fileTypesEnum.ID_BACK,
          text: this.$t("newApplication.fileUpload.idBackSide"),
        },
        {
          value: fileTypesEnum.PASSPORT,
          text: this.$t("newApplication.fileUpload.passport"),
        },
        // disabled options at the moment
        // {
        //   value: "9",
        //   text: this.$t("newApplication.fileUpload.driverLicenseFront")
        // },
        // {
        //   value: "10",
        //   text: this.$t("newApplication.fileUpload.driverLicenseBack")
        // },
        {
          value: fileTypesEnum.INCOME_DECLARATION,
          text: this.$t("newApplication.fileUpload.incomeDeclaration"),
        },
        {
          value: fileTypesEnum.SB_INSURANCE_DOC,
          text: this.$t("newApplication.fileUpload.sbInsuranceDoc"),
        },
        {
          value: fileTypesEnum.REFINANCE_DOC,
          text: this.$t("newApplication.fileUpload.refinanceDoc"),
        },
        {
          value: fileTypesEnum.OTHER,
          text: this.$t("newApplication.fileUpload.otherDoc"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_FRONT,
          text: this.$t("newApplication.fileUpload.residencePermitFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_BACK,
          text: this.$t("newApplication.fileUpload.residencePermitBack"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_FRONT,
          text: this.$t("newApplication.fileUpload.residencePermitTemporaryFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_BACK,
          text: this.$t("newApplication.fileUpload.residencePermitTemporaryBack"),
        },
        {
          value: fileTypesEnum.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO,
          text: this.$t("newApplication.fileUpload.electronicResidencePermitCheckPhoto"),
        },
        {
          value: fileTypesEnum.BANK_ACCOUNT_REPORT,
          text: this.$t("newApplication.fileUpload.bankAccountReport"),
        },
        {
          value: fileTypesEnum.APPLICATION_COPY,
          text: this.$t("newApplication.fileUpload.applicationCopy"),
        },
        {
          value: fileTypesEnum.APPLICATION_MARKETING_AGREEMENT,
          text: this.$t("newApplication.fileUpload.applicationMarketingAgreementCopy"),
        },
        {
          value: fileTypesEnum.INCOME_DECLARATION_SPOUSE,
          text: this.$t("newApplication.fileUpload.decisionApprovalDocument"),
        },
        {
          value: fileTypesEnum.ID_FRONT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseIdFrontSide"),
        },
        {
          value: fileTypesEnum.ID_BACK_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseIdBackSide"),
        },
        {
          value: fileTypesEnum.PASSPORT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spousePassport"),
        },
        // disabled options at the moment
        // {
        //   value: "11",
        //   text: this.$t("newApplication.fileUpload.spouseDriverLicenseFront")
        // },
        // {
        //   value: "12",
        //   text: this.$t("newApplication.fileUpload.spouseDriverLicenseBack")
        // }
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_FRONT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_BACK_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitBack"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_FRONT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitTemporaryFront"),
        },
        {
          value: fileTypesEnum.RESIDENCE_PERMIT_TEMPORARY_BACK_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseResidencePermitTemporaryBack"),
        },
        {
          value: fileTypesEnum.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseElectronicResidencePermitCheckPhoto"),
        },
        {
          value: fileTypesEnum.BANK_ACCOUNT_REPORT_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseBankAccountReport"),
        },
        {
          value: fileTypesEnum.APPLICATION_COPY_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseApplicationCopy"),
        },
        {
          value: fileTypesEnum.APPLICATION_MARKETING_AGREEMENT_COPY_SPOUSE,
          text: this.$t("newApplication.fileUpload.spouseApplicationMarketingAgreementCopy"),
        },
        {
          value: fileTypesEnum.SIGNED_SBD_AGREEMENT,
          text: this.$t("newApplication.fileUpload.signedSbdAgreement"),
        },
      ];
    },
    commonMimeTypes(): FileSignatureInterface[] {
      // table from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      return [
        {
          extension: "aac",
          type: "audio/aac",
        },
        {
          extension: "abw",
          type: "application/x-abiword",
        },
        {
          extension: "arc",
          type: "application/x-freearc",
        },
        {
          extension: "avif",
          type: "image/avif",
        },
        {
          extension: "avi",
          type: "video/x-msvideo",
        },
        {
          extension: "azw",
          type: "application/vnd.amazon.ebook",
        },
        {
          extension: "bin",
          type: "application/octet-stream",
        },
        {
          extension: "bmp",
          type: "image/bmp",
        },
        {
          extension: "bz",
          type: "application/x-bzip",
        },
        {
          extension: "bz2",
          type: "application/x-bzip2",
        },
        {
          extension: "cda",
          type: "application/x-cdf",
        },
        {
          extension: "csh",
          type: "application/x-csh",
        },
        {
          extension: "css",
          type: "text/css",
        },
        {
          extension: "csv",
          type: "text/csv",
        },
        {
          extension: "doc",
          type: "application/msword",
        },
        {
          extension: "docx",
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        },
        {
          extension: "eot",
          type: "application/vnd.ms-fontobject",
        },
        {
          extension: "epub",
          type: "application/epub+zip",
        },
        {
          extension: "gz",
          type: "application/gzip",
        },
        {
          extension: "gif",
          type: "image/gif",
        },
        {
          extension: "htm",
          type: "text/html",
        },
        {
          extension: "html",
          type: "text/html",
        },
        {
          extension: "ico",
          type: "image/vnd.microsoft.icon",
        },
        {
          extension: "ics",
          type: "text/calendar",
        },
        {
          extension: "jar",
          type: "application/java-archive",
        },
        {
          extension: "jpeg",
          type: "image/jpeg",
        },
        {
          extension: "jpg",
          type: "image/jpeg",
        },
        {
          extension: "js",
          type: "text/javascript",
        },
        {
          extension: "json",
          type: "application/json",
        },
        {
          extension: "jsonld",
          type: "application/ld+json",
        },
        {
          extension: "mid",
          type: "audio/midi",
        },
        {
          extension: "midi",
          type: "audio/midi",
        },
        {
          extension: "mjs",
          type: "text/javascript",
        },
        {
          extension: "mp3",
          type: "audio/mpeg",
        },
        {
          extension: "mp4",
          type: "video/mp4",
        },
        {
          extension: "mpeg",
          type: "video/mpeg",
        },
        {
          extension: "mpkg",
          type: "application/vnd.apple.installer+xml",
        },
        {
          extension: "odp",
          type: "application/vnd.oasis.opendocument.presentation",
        },
        {
          extension: "ods",
          type: "application/vnd.oasis.opendocument.spreadsheet",
        },
        {
          extension: "odt",
          type: "application/vnd.oasis.opendocument.text",
        },
        {
          extension: "oga",
          type: "audio/ogg",
        },
        {
          extension: "ogv",
          type: "video/ogg",
        },
        {
          extension: "ogx",
          type: "application/ogg",
        },
        {
          extension: "opus",
          type: "audio/opus",
        },
        {
          extension: "otf",
          type: "font/otf",
        },
        {
          extension: "png",
          type: "image/png",
        },
        {
          extension: "pdf",
          type: "application/pdf",
        },
        {
          extension: "php",
          type: "application/x-httpd-php",
        },
        {
          extension: "ppt",
          type: "application/vnd.ms-powerpoint",
        },
        {
          extension: "pptx",
          type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        },
        {
          extension: "rar",
          type: "application/vnd.rar",
        },
        {
          extension: "rtf",
          type: "application/rtf",
        },
        {
          extension: "sh",
          type: "application/x-sh",
        },
        {
          extension: "svg",
          type: "image/svg+xml",
        },
        {
          extension: "tar",
          type: "application/x-tar",
        },
        {
          extension: "tif",
          type: "image/tiff",
        },
        {
          extension: "tiff",
          type: "image/tiff",
        },
        {
          extension: "ts",
          type: "video/mp2t",
        },
        {
          extension: "ttf",
          type: "font/ttf",
        },
        {
          extension: "txt",
          type: "text/plain",
        },
        {
          extension: "vsd",
          type: "application/vnd.visio",
        },
        {
          extension: "wav",
          type: "audio/wav",
        },
        {
          extension: "weba",
          type: "audio/webm",
        },
        {
          extension: "webm",
          type: "video/webm",
        },
        {
          extension: "webp",
          type: "image/webp",
        },
        {
          extension: "woff",
          type: "font/woff",
        },
        {
          extension: "woff2",
          type: "font/woff2",
        },
        {
          extension: "xhtml",
          type: "application/xhtml+xml",
        },
        {
          extension: "xls",
          type: "application/vnd.ms-excel",
        },
        {
          extension: "xlsx",
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        {
          type: "application/vnd.ms-excel.sheet.macroEnabled.12",
          extension: "xlsm",
        },
        {
          extension: "xml",
          type: "application/xml",
        },
        {
          extension: "xul",
          type: "application/vnd.mozilla.xul+xml",
        },
        {
          extension: "zip",
          type: "application/zip",
        },
        {
          extension: "3gp",
          type: "video/3gpp",
        },
        {
          extension: "3g2",
          type: "video/3gpp2",
        },
        {
          extension: "7z",
          type: "application/x-7z-compressed",
        },
      ];
    },
  },
});

export default fileDownload;
